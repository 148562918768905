import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@assets/chevron-left.svg';
import ChevronRightIcon from '@assets/chevron-right.svg';
import Wrapper from './Wrapper';
import Slide from './Slide';
import ArrowsWrapper from './ArrowsWrapper';
import StyledContentSlider from './ContentSlider.styled';

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
  },
}));

const ContentSlider = ({ children }) => {
  const classes = useStyles(),
    childrenArr = React.Children.toArray(children),
    [currentIndex, setCurrentIndex] = useState(0),
    [translateValue, setTranslateValue] = useState(0),
    slidesRef = useRef([]);

  const goToPrevSlide = useCallback(() => {
    if (!currentIndex) return;

    setCurrentIndex(i => i - 1);
    setTranslateValue(v => v + slidesRef.current[currentIndex - 1].clientWidth);
  }, [currentIndex, setCurrentIndex, setTranslateValue, slidesRef]);

  const goToNextSlide = useCallback(() => {
    if (currentIndex === childrenArr.length - 1) {
      setCurrentIndex(0);
      setTranslateValue(0);
    } else {
      setCurrentIndex(i => i + 1);
      setTranslateValue(v => v - slidesRef.current[currentIndex].clientWidth);
    }
  }, [currentIndex, setCurrentIndex, setTranslateValue, slidesRef, childrenArr]);
  return (
    <>
      <StyledContentSlider>
        <Wrapper translateValue={translateValue}>
          {childrenArr.map((childItem, i) => (
            <Slide ref={el => (slidesRef.current[i] = el)} key={i}>
              {childItem}
            </Slide>
          ))}
        </Wrapper>

        <ArrowsWrapper>
          <IconButton
            onClick={goToPrevSlide}
            disabled={!currentIndex}
            className={classes.iconButton}
          >
            <ChevronLeftIcon />
          </IconButton>
          <span>
            {currentIndex + 1} / {childrenArr.length}
          </span>
          <IconButton onClick={goToNextSlide} className={classes.iconButton}>
            <ChevronRightIcon />
          </IconButton>
        </ArrowsWrapper>
      </StyledContentSlider>
    </>
  );
};

export default ContentSlider;

ContentSlider.propTypes = {
  children: PropTypes.node.isRequired,
};
