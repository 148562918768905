import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from 'react-youtube';
import { Fade } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import StyledVideo from './Video.styled';

const Video = ({ videoId, videoHost }) => {
  const theme = useTheme(),
    [videoLoaded, setVideoLoaded] = useState(false);

  const handleLoadVideo = useCallback(() => {
    setVideoLoaded(true);
  }, []);

  const videoContent = useMemo(() => {
    switch (videoHost) {
      case 'vimeo':
        return (
          <Vimeo video={videoId} color={theme.colors.bg.slice(1)} onLoaded={handleLoadVideo} />
        );
      case 'youtube':
        return <YouTube videoId={videoId} onReady={handleLoadVideo} />;
      default:
        return null;
    }
  }, [handleLoadVideo, theme.colors.bg, videoHost, videoId]);

  return (
    <StyledVideo>
      <Fade in={videoLoaded} timeout={300}>
        <div>{videoContent}</div>
      </Fade>
    </StyledVideo>
  );
};

export default Video;

Video.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoHost: PropTypes.string.isRequired,
};
