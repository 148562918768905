import React from 'react';
import { Link } from 'gatsby';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const CtaButton = ({ href, entry, variant, children, className }) => {
  const entryUrl = entry && entry.uri ? entry.uri : null;
  const url = entryUrl ? entryUrl : href;
  const isLinkInternal = /^\/(?!\/)/.test(url);

  return (
    <Button
      variant={variant ? variant : 'contained'}
      component={isLinkInternal ? Link : 'a'}
      to={isLinkInternal ? url : undefined}
      href={!isLinkInternal ? url : undefined}
      className={className ? className : undefined}
    >
      {children}
    </Button>
  );
};

CtaButton.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  entry: PropTypes.shape({
    uri: PropTypes.string,
  }),
  variant: PropTypes.string,
};

export default CtaButton;
