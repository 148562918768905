import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Wrapper from './Wrapper';
import Slide from './Slide';
import ArrowsWrapper from './ArrowsWrapper';
import ChevronLeftIcon from '@assets/chevron-left.svg';
import ChevronRightIcon from '@assets/chevron-right.svg';
import StyledImageSlider from './ImageSlider.styled';
import { useColorByBackground } from '../../hooks/helpers';

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
  },
  slideBox: {
    position: 'relative',
  },
  pagination: {
    overflow: 'auto',
  },
  blockTitle: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
    },
  },
}));

const ImageSlider = ({
  items,
  spacing,
  gridContainerProps,
  blockTitle,
  align,
  color: backgroundColor,
  ...props
}) => {
  const slideSpace = 5;

  const classes = useStyles(),
    theme = useTheme(),
    [currentIndex, setCurrentIndex] = useState(0),
    [translateValue, setTranslateValue] = useState(0),
    slidesRef = useRef([]),
    isSmall = useMediaQuery(theme.breakpoints.down('xs')),
    textColor = useColorByBackground(backgroundColor);

  const goToPrevSlide = useCallback(() => {
    if (!currentIndex) return;

    setCurrentIndex(i => i - 1);
    setTranslateValue(
      v => v + slidesRef.current[currentIndex - 1].clientWidth + theme.spacing(slideSpace)
    );
  }, [currentIndex, setCurrentIndex, setTranslateValue, slidesRef, theme]);

  const goToNextSlide = useCallback(() => {
    if (currentIndex === items.length - 1) {
      setCurrentIndex(0);
      setTranslateValue(0);
    } else {
      setCurrentIndex(i => i + 1);
      setTranslateValue(
        v => v - slidesRef.current[currentIndex].clientWidth - theme.spacing(slideSpace)
      );
    }
  }, [currentIndex, setCurrentIndex, setTranslateValue, slidesRef, theme, items]);
  return (
    <StyledImageSlider {...props}>
      <Container disableGutters={isSmall}>
        <Grid
          container
          {...gridContainerProps}
          justify={align === 'center' ? 'center' : 'flex-start'}
        >
          <Grid item xs={12} md={10} lg={align === 'center' ? 8 : 10}>
            {!!blockTitle && !!blockTitle.length && (
              <h3 className={classes.blockTitle}>{blockTitle}</h3>
            )}
            <Box {...spacing} className={classes.slideBox}>
              <Wrapper translateValue={translateValue}>
                {items.map((item, i) => {
                  return (
                    <Slide
                      index={i}
                      space={slideSpace}
                      ref={el => (slidesRef.current[i] = el)}
                      key={i}
                      item={item}
                      centeredContainer={align === 'center'}
                      color={textColor}
                      backgroundColor={backgroundColor}
                    />
                  );
                })}
              </Wrapper>

              <ArrowsWrapper>
                <IconButton
                  onClick={goToPrevSlide}
                  disabled={!currentIndex}
                  className={classes.iconButton}
                  aria-label='Previous slide'
                >
                  <ChevronLeftIcon />
                </IconButton>
                <span className={classes.pagination}>
                  {currentIndex + 1} / {items.length}
                </span>
                <IconButton
                  onClick={goToNextSlide}
                  className={classes.iconButton}
                  aria-label='Next slide'
                >
                  <ChevronRightIcon />
                </IconButton>
              </ArrowsWrapper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledImageSlider>
  );
};

export default ImageSlider;

ImageSlider.propTypes = {
  items: PropTypes.array.isRequired,
  spacing: PropTypes.object.isRequired,
  slideSpace: PropTypes.number,
  gridContainerProps: PropTypes.object,
};

ImageSlider.defaultProps = {
  slideSpace: 5,
};
