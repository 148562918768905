import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import CTAButton from '@c/CTAButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  title: {
    ...theme.typography.h1,
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(4),
  },
  text: {
    ...theme.typography.body1,
    marginBottom: theme.spacing(5),
  },
  button: {
    padding: `${theme.spacing(3)}px ${theme.spacing(10)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(15)}px`,
    },
  },
}));

const InfoText = ({ blockTitle, subtitle, text, ctaButton }) => {
  const classes = useStyles();
  const button = ctaButton[0];

  return (
    <div className={classes.root}>
      <Container>
        <Grid container justify='center'>
          <Grid item xs={12} md={10} lg={8}>
            <h2 className={classes.title}>{blockTitle}</h2>
            <h4 className={classes.subtitle}>{subtitle}</h4>
            <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }}></div>

            {button && (
              <CTAButton
                href={button.externalUrl}
                entry={button.entry[0]}
                className={classes.button}
              >
                {button.text}
              </CTAButton>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default InfoText;
