import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button } from '@material-ui/core';
import ChevronRightIcon from '@assets/chevron-right.svg';
import Divider from '@c/Divider';
import Picture from '@c/Picture';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  category: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: '1.25rem',
    lineHeight: 1.4,
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: `${theme.spacing(0.5)}px !important`,
    },
  },
}));

const CTAButtonBlock = ({
  blockTitle,
  category,
  intro,
  entry,
  buttonText,
  image,
  typeHandle,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box fontFamily='fontFamily' {...props}>
      <Grid item xs={12}>
        <Divider mb={{ xs: 7, md: 12 }} />
      </Grid>

      <Grid container spacing={5} justify='space-between'>
        {blockTitle && (
          <Grid item xs={12}>
            <Box>
              <h2>{blockTitle}</h2>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Picture alt={image[0].title} {...image[0]} />
        </Grid>

        <Grid item xs={12} md={6} className={classes.text}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            height='100%'
          >
            <Box mr={{ xs: 4, md: 0 }}>
              {category && <Box className={classes.category}>{category}</Box>}
              <Box className={classes.description}>{intro}</Box>
              <Button
                variant='outlined'
                size='small'
                endIcon={<ChevronRightIcon />}
                component={Link}
                to={`/${entry[0].uri}`}
                title={buttonText}
              >
                {buttonText}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider mt={{ xs: 7, md: 12 }} />
      </Grid>
    </Box>
  );
};

export default CTAButtonBlock;

CTAButtonBlock.propTypes = {
  blockTitle: PropTypes.string,
  category: PropTypes.string,
  entry: PropTypes.array.isRequired,
  intro: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.array.isRequired,
};
