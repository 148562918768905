import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Grid, Button, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';
import ChevronRightIcon from '@assets/chevron-right.svg';
import Divider from '@c/Divider';

const useStyles = makeStyles(theme => ({
  intro: {
    '& a': {
      display: 'block',
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 'normal',
      color: theme.colors.dark,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.0625rem',
      },
    },
  },
  counter: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    color: transparentize(0.32, theme.colors.dark),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      lineHeight: 1.2,
      letterSpacing: '0.4px',
    },
  },
}));

const CareerCases = ({ intro, entries, readMoreCta }) => {
  const classes = useStyles();

  return (
    <Grid container>
      {intro && (
        <Grid item xs={12} sm={10} lg={8} xl={7}>
          <Box
            pl={{ xs: 0, md: 8 }}
            mb={{ xs: 12, md: 19.5 }}
            className={classes.intro}
            dangerouslySetInnerHTML={{ __html: intro }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={11}>
        <Box pl={{ xs: 0, md: 8 }}>
          <Grid container>
            {entries.map((block, index) => (
              <React.Fragment key={block.id}>
                <Grid item xs={12}>
                  <Divider mb={{ xs: 5, md: 12 }} visible={!index} />
                </Grid>

                <Grid item xs={6} className={classes.counter}>
                  {index + 1 < 10 && 0}
                  {index + 1} —
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={12} md={6}>
                  <Box mt={2} mb={{ xs: 2, md: 4 }}>
                    <h3>{block.title}</h3>
                  </Box>
                  <Hidden smDown>
                    <Button
                      variant='outlined'
                      size='small'
                      endIcon={<ChevronRightIcon />}
                      component={Link}
                      to={`/${block.uri}`}
                      title={`${readMoreCta} - ${block.title}`}
                    >
                      {readMoreCta}
                    </Button>
                  </Hidden>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  dangerouslySetInnerHTML={{ __html: block.featuredContent[0]?.text }}
                />

                <Hidden mdUp>
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <Button
                        variant='outlined'
                        size='small'
                        endIcon={<ChevronRightIcon />}
                        component={Link}
                        to={`/${block.uri}`}
                        title={`${readMoreCta} - ${block.title}`}
                      >
                        {readMoreCta}
                      </Button>
                    </Box>
                  </Grid>
                </Hidden>

                <Grid item xs={12}>
                  <Divider mt={{ xs: 5, md: 12 }} />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CareerCases;

CareerCases.propTypes = {
  intro: PropTypes.string,
  entries: PropTypes.array.isRequired,
  readMoreCta: PropTypes.string.isRequired,
};

CareerCases.defaultProps = {
  intro: null,
};
