import styled from 'styled-components';

const StyledIcon = styled.div`
  display: flex;
  width: 3.5rem;
  height: 3.5rem;
  alignitems: center;

  ${({ theme }) => `
    ${theme.breakpoints.down('lg')} {
      margin: auto;
    }

    ${theme.breakpoints.down('sm')} {
      width: 3rem;
      height: 3rem;
    }

    ${theme.breakpoints.down('xs')} {
      width: 2.5rem;
      height: 2.5rem;
    }
  `}
`;

export default StyledIcon;
