import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { transparentize } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => ({
  position: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: transparentize(0.32, theme.colors.dark),
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      lineHeight: 1.2,
      letterSpacing: '0.4px',
    },
  },
  name: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.95rem',
    },
  },
  email: {
    display: 'block',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.71,
    textDecoration: 'underline',
  },
}));

const TeamCards = ({ title, employees }) => {
  const classes = useStyles(),
    isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <>
      {title && (
        <Box mb={{ xs: 5, md: 11 }} className='space-p-l-6 space-p-r-6'>
          <h2>{title}</h2>
        </Box>
      )}
      <Box>
        <Grid container spacing={isSmall ? 4 : 5}>
          {employees.map(employee => (
            <Grid item xs={6} md={4} lg={3} key={employee.id}>
              <Box pb={{ xs: 5, md: 10 }}>
                <Picture alt={employee.fullName} {...employee.photo} />
                <Box pl={{ xs: 0, md: 4 }} pt={{ xs: 2, md: 3 }}>
                  <div className={classes.position}>{employee.jobTitle}</div>
                  <div className={classes.name}>{employee.fullName}</div>
                  {employee.email && (
                    <a href={`mailto:${employee.email}`} className={classes.email}>
                      E-post
                    </a>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TeamCards;

TeamCards.propTypes = {
  title: PropTypes.string,
  employees: PropTypes.array.isRequired,
};

TeamCards.deafultProps = {
  title: null,
};
