import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Picture from '../Picture';
import { Link } from 'gatsby';
import { useColorByBackground } from '../../hooks/helpers';

const useStyles = makeStyles(theme => ({
  root: ({ backgroundColor }) => ({
    display: 'flex',
    padding: `${theme.spacing(8)}px 0`,
    backgroundColor: backgroundColor || theme.colors.orange,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(16)}px 0`,
    },
  }),
  title: ({ textColor }) => ({
    ...theme.typography.h3,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
    color: textColor || theme.colors.dark,
  }),
  linksList: {
    display: 'flex',
    flexDirection: 'column',
  },
  linksItems: ({ textColor }) => ({
    fontSize: '1.375',
    lineHeight: '2.5',
    color: textColor || theme.colors.black,
  }),
  imageWrapper: {
    paddingTop: '56.25%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing(4)}px`,
    },
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    '& img': {
      height: '100%',
    },
  },
}));

const Links = ({ blockTitle, linksItems, image, color: backgroundColor }) => {
  const textColor = useColorByBackground(backgroundColor);
  const classes = useStyles({ backgroundColor, textColor });

  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <h3 className={classes.title}>{blockTitle}</h3>
            <div className={classes.linksList}>
              {linksItems.map((item, index) => (
                <Link
                  to={item.entry[0] ? `/${item.entry[0].uri}` : item.linkUrl ? item.linkUrl : ''}
                  target={!item.entry[0] && item.linkUrl && '_blank'}
                  key={index}
                  className={classes.linksItems}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.imageWrapper}>
              <Picture alt={image[0].title} className={classes.image} {...image[0]} width='100%' />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Links;
