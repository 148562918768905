import React from 'react';
import { useLatestEntries } from '@hooks/cms';
import FeaturedContentGrid from '@c/FeaturedContentGrid';
import ClientsCards from '@c/ClientsCards';
import CareerCases from '@c/CareerCases';

const LatestEntries = ({ sectionId, limit, ...props }) => {
  const [latestEntries, blogMainPageUrl, casesMainPageUrl] = useLatestEntries(sectionId, limit);

  switch (latestEntries[0].sectionHandle) {
    case 'cases':
      return (
        <FeaturedContentGrid {...props} ctaUrl={`/${casesMainPageUrl}`} entries={latestEntries} />
      );
    case 'blog':
      return (
        <FeaturedContentGrid
          {...props}
          ctaUrl={`/${blogMainPageUrl}`}
          smallGrid
          entries={latestEntries}
        />
      );
    case 'clients':
      return <ClientsCards {...props} clients={latestEntries} />;
    case 'careerListings':
      return <CareerCases {...props} entries={latestEntries} />;
    default:
      return null;
  }
};

export default LatestEntries;
