import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';
import CloseIcon from '@assets/close.svg';
import Picture from '@c/Picture';
import FormHandler from '@c/forms';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(90),
    },
  },
  jobTitle: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    color: transparentize(0.32, theme.colors.dark),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  name: {
    fontSize: '1.75rem',
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const ContactFormDialog = ({
  open,
  onClose,
  fullScreen,
  employee,
  bookCta,
  confirmationText,
  form,
}) => {
  const classes = useStyles(),
    [isConfirmTextShowing, setIsConfirmTextShowing] = useState(false);

  const showConfirmationText = useCallback(() => {
    setIsConfirmTextShowing(true);
  }, []);

  useEffect(() => {
    if (open) setIsConfirmTextShowing(false);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <Box textAlign='right'>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box textAlign='center'>
          {isConfirmTextShowing ? (
            <>
              <Box mb={4} dangerouslySetInnerHTML={{ __html: confirmationText }} />
              <Button type='button' fullWidth variant='contained' onClick={onClose}>
                Lukk
              </Button>
            </>
          ) : (
            <>
              <Picture mx='auto' width='218px' alt={employee.fullName} {...employee.photo} />
              <Box className={classes.jobTitle}>{employee.jobTitle}</Box>
              <Box className={classes.name}>{employee.fullName}</Box>
              <FormHandler
                form={form}
                employeeId={employee.id}
                bookCta={bookCta}
                onSubmitCallback={showConfirmationText}
              />
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ContactFormDialog;

ContactFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  employee: PropTypes.object.isRequired,
  bookCta: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.number.isRequired,
    handle: PropTypes.string.isRequired,
  }).isRequired,
};
