import React, { useState, useCallback, useEffect } from 'react';
import { Fade, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Quote from './Quote';

const QuoteContainer = ({ mt, mb, pt, pb, ...props }) => {
  const { typeHandle, fullHeight, image, backgroundColor, overlayColor } = props,
    theme = useTheme(),
    [lastImageSrc, setLastImageSrc] = useState(null),
    [imageLoaded, setImageLoaded] = useState(false),
    isDownXs = useMediaQuery(theme.breakpoints.down('xs')),
    isDownSm = useMediaQuery(theme.breakpoints.down('sm')),
    isDownMd = useMediaQuery(theme.breakpoints.down('md')),
    isDownLg = useMediaQuery(theme.breakpoints.down('lg')),
    isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const handleLoadImage = useCallback(() => {
    setImageLoaded(true);
  }, []);

  useEffect(() => {
    if ((isDownLg || isUpLg) /* breakpoints initialized */ && image && image[0] && !fullHeight) {
      let imageSrc = image[0].url;

      if (image[0].lgUrl && isDownLg) {
        imageSrc = image[0].lgUrl;
      } else if (image[0].mdUrl && isDownMd) {
        imageSrc = image[0].mdUrl;
      } else if (image[0].smUrl && isDownSm) {
        imageSrc = image[0].smUrl;
      } else if (image[0].xsUrl && isDownXs) {
        imageSrc = image[0].xsUrl;
      }

      if (!imageSrc) {
        return;
      }

      if (!imageSrc.startsWith(process.env.GATSBY_BACKEND_URL)) {
        imageSrc = `${process.env.GATSBY_BACKEND_URL}/${imageSrc}`;
      }

      if (imageSrc !== lastImageSrc) {
        setImageLoaded(false);
        setLastImageSrc(imageSrc);
        let img = new Image();
        img.src = imageSrc;
        img.onload = handleLoadImage;
        img.onerror = handleLoadImage;
      }
    }
  }, [
    typeHandle,
    fullHeight,
    image,
    lastImageSrc,
    handleLoadImage,
    isDownXs,
    isDownSm,
    isDownMd,
    isDownLg,
    isUpLg,
  ]);

  if (image && image[0] && !fullHeight) {
    return (
      <Box
        bgcolor={backgroundColor || overlayColor || theme.colors.black}
        mt={mt}
        mb={mb}
        pt={pt}
        pb={pb}
      >
        <Fade in={imageLoaded} timeout={300}>
          <Box>
            <Quote {...props} />
          </Box>
        </Fade>
      </Box>
    );
  }

  return <Quote {...props} />;
};

export default QuoteContainer;
