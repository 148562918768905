import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider as MUIDivider } from '@material-ui/core';
import { spacing } from '@material-ui/system';

const Divider = styled(({ visible, ...props }) => <MUIDivider {...props} />)`
  ${spacing}
  opacity: ${({ visible }) => (visible ? 0.12 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background-color: ${({ theme }) => theme.colors.dark};
`;

export default Divider;

Divider.propTypes = {
  visible: PropTypes.bool,
};

Divider.defaultProps = {
  visible: true,
};
