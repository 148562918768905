import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const bgColor = css`
  background: ${props => props.bgColor};
`;

const bgImage = css`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${({ bgImage, theme }) =>
    bgImage.url &&
    `
    background-image: url(${bgImage.url});
  `}

  ${({ bgImage, theme }) =>
    bgImage.lgUrl &&
    `
    ${theme.breakpoints.down('lg')} {
      background-image: url(${bgImage.lgUrl});
    }
  `}

  ${({ bgImage, theme }) =>
    bgImage.mdUrl &&
    `
    ${theme.breakpoints.down('md')} {
      background-image: url(${bgImage.mdUrl});
    }
  `}

  ${({ bgImage, theme }) =>
    bgImage.smUrl &&
    `
    ${theme.breakpoints.down('sm')} {
      background-image: url(${bgImage.smUrl});
    }
  `}

  ${({ bgImage, theme }) =>
    bgImage.xsUrl &&
    `
    ${theme.breakpoints.down('xs')} {
      background-image: url(${bgImage.xsUrl});
    }
  `}
`;

const overlay = css`
  position: relative;

  & > * {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.3s linear;
    background-color: ${({ overlay }) => transparentize(1 - overlay.opacity / 100, overlay.color)};
  }
`;

const notFullScreen = css`
  padding-top: ${({ theme }) => theme.spacing(12.5)}px;
  padding-bottom: ${({ theme }) => theme.spacing(12.5)}px;
  margin-top: ${({ theme }) => theme.spacing(12.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(12.5)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }) => theme.spacing(26)}px;
    padding-bottom: ${({ theme }) => theme.spacing(26)}px;
    margin-top: ${({ theme }) => theme.spacing(32)}px;
    margin-bottom: ${({ theme }) => theme.spacing(32)}px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${props => props.bgColor && bgColor}
  ${props => props.bgImage && bgImage}
  ${props => props.overlay && overlay}
`;

export default styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  line-height: 1.33;
  align-items: center;
  color: ${props => props.textColor};

  h1,
  h2 {
    line-height: 1.22;
    font-size: 1.6875rem;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      font-size: 3.375rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      font-size: 3.26vw;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 3vw;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      font-size: 2.625rem;
    }
  }

  p {
    font-size: 1.5rem;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: 1.0625rem;
    }
  }

  & a {
    color: inherit;
  }

  ${props => !props.fullScreen && notFullScreen}
`;
