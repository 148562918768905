import { PAGE_TYPE_LAYOUT, PAGE_TYPE_CASE, PAGE_TYPE_ARTICLE } from '@context/PageTypeContext';

export const getContentParams = pageType => {
  switch (pageType) {
    case PAGE_TYPE_LAYOUT:
      return {
        spaces: {
          my: { xs: 7, md: 12 },
        },
        gridContainerProps: {},
        gridItemProps: {
          xs: 12,
          md: 10,
          lg: 8,
          xl: 7,
          className: 'space-p-l-6 space-p-r-6',
        },
        mediaItemProps: {
          xs: 12,
        },
      };
    case PAGE_TYPE_CASE:
      return {
        spaces: {
          my: { xs: 7, md: 19.5 },
        },
        gridContainerProps: {
          justify: 'center',
        },
        gridItemProps: {
          xs: 12,
          md: 10,
          lg: 8,
        },
        mediaItemProps: {
          xs: 12,
        },
      };
    case PAGE_TYPE_ARTICLE:
      return {
        spaces: {
          my: { xs: 7, md: 12 },
        },
        gridContainerProps: {
          justify: 'center',
        },
        gridItemProps: {
          xs: 12,
          md: 10,
          lg: 8,
        },
        mediaItemProps: {
          xs: 12,
          lg: 10,
        },
      };
    default:
      return {
        spaces: {},
        gridContainerProps: {},
        gridItemProps: {},
        mediaItemProps: {},
      };
  }
};
