import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import Divider from '@c/Divider';

export default styled.div`
  ${spacing}
  & + & {
    margin-top: 0 !important;

    ${Divider}:first-child {
      visibility: hidden;
    }
  }

  &:not(:last-child) {
    margin-bottom: 0 !important;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & + & {
      margin-top: ${({ theme }) => theme.spacing(3.5)}px !important;
    }
  }
`;
