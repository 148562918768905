import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => {
  return {
    slide: ({ centeredContainer }) => ({
      display: 'inline-block',
      marginLeft: theme.spacing(5),
      position: 'relative',
      width: centeredContainer ? '1016px' : '896px',
      maxWidth: '100%',
    }),
    ratioSquare: ({ centeredContainer }) => ({
      paddingTop: centeredContainer ? '57.4%' : '62.5%',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '62.5%',
      },
    }),
    image: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      '& img': {
        height: '100%',
      },
    },
    imageWithContent: {
      width: '50%',
      left: 'auto',
      right: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    content: ({ backgroundColor, color }) => ({
      display: 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      left: 0,
      top: 0,
      width: '50%',
      height: '100%',
      position: 'absolute',
      padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
      backgroundColor: backgroundColor || theme.colors.orange,
      color: color || theme.colors.dark,
      lineHeight: '1.6',
      whiteSpace: 'normal',
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        display: 'flex',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(18),
        padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(
          7
        )}px`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.typography.pxToRem(20),
      },
    }),
    title: {
      fontSize: '1.5rem',
      lineHeight: '1.65',
      marginBottom: '0.75rem',
    },
  };
});

const Slide = React.forwardRef(
  ({ item, space, index, centeredContainer, color, backgroundColor }, ref) => {
    const classes = useStyles({ centeredContainer, color, backgroundColor });
    const theme = useTheme();
    const marginLeft = index > 0 ? theme.spacing(space) : 0;
    const title = item.blockTitle;
    const description = item.description;
    const hasContent = Boolean(title || description);
    const image = item.image[0];

    return (
      <div className={classes.slide} ref={ref} style={{ marginLeft }}>
        <div className={classes.ratioSquare}></div>

        {hasContent && (
          <div className={classes.content}>
            {title && <h4 className={classes.title}>{title}</h4>}
            {description && <div>{description}</div>}
          </div>
        )}

        <Picture
          className={`${classes.image} ${hasContent && classes.imageWithContent}`}
          {...image}
        />
      </div>
    );
  }
);

export default Slide;
