import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@c/Divider';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
  },
  gridItem: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
}));

const SideBySide = ({ text, image }) => {
  const classes = useStyles(),
    isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={isSmall ? 3 : 10} classes={{ root: classes.gridContainer }}>
      <Grid
        container
        item
        xs={12}
        md={6}
        direction='column'
        justify='center'
        classes={{ root: classes.gridItem }}
      >
        <Picture mb={{ xs: 2, md: 0 }} alt={image[0].title} {...image[0]} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={5}
        direction='column'
        justify='space-between'
        classes={{ root: classes.gridItem }}
      >
        <Divider visible={/*!index && */ !isSmall} />
        <div dangerouslySetInnerHTML={{ __html: text }} />
        <Divider visible={!isSmall} />
      </Grid>
    </Grid>
  );
};

export default SideBySide;

SideBySide.propTypes = {
  image: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  text: PropTypes.string.isRequired,
};
