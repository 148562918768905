import React from 'react';
import PropTypes from 'prop-types';
import { PAGE_TYPE_LAYOUT, PAGE_TYPE_CASE, PAGE_TYPE_ARTICLE } from './PageTypeContext.constants';
import PageTypeContext from './PageTypeContext';

const PageTypeContextProvider = ({ value, children }) => {
  return <PageTypeContext.Provider value={value}>{children}</PageTypeContext.Provider>;
};

export default PageTypeContextProvider;

PageTypeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOf([PAGE_TYPE_LAYOUT, PAGE_TYPE_CASE, PAGE_TYPE_ARTICLE]).isRequired,
};
