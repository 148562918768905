import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const mobile = css`
  margin-top: ${({ theme }) => theme.spacing(1)}px;

  && p,
  && p > a {
    font-size: 0.75rem;
  }
`;

export default styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)}px;

  && p,
  && p > a {
    font-family: EuclidCircular, serif;
    font-size: 1rem;
    line-height: normal;
    color: ${({ theme }) => transparentize(0.32, theme.colors.dark)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
