import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import QuoteIcon from '@assets/quote.svg';
import Source from './Source';
import StyledInlineQuote from './InlineQuote.styled';

const InlineQuote = ({ text, source }) => (
  <Box display={{ xs: 'block', md: 'flex' }}>
    <Box pt={{ xs: 0, md: 1 }} mr={{ xs: 0, md: 3 }} mb={{ xs: 1, md: 0 }}>
      <QuoteIcon />
    </Box>
    <div>
      <StyledInlineQuote dangerouslySetInnerHTML={{ __html: text }} />
      {source && <Source>— {source}</Source>}
    </div>
  </Box>
);

export default InlineQuote;

InlineQuote.propTypes = {
  text: PropTypes.string.isRequired,
  source: PropTypes.string,
};
