import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, Grid, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';
import ChevronRightIcon from '@assets/chevron-right.svg';
import Divider from '@c/Divider';
import Picture from '@c/Picture';
import ContactFormDialog from './ContactFormDialog';

const useStyles = makeStyles(theme => ({
  jobTitle: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    color: transparentize(0.32, theme.colors.dark),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      lineHeight: 1.2,
      letterSpacing: '0.4px',
      marginBottom: theme.spacing(1),
    },
  },
  name: {
    fontSize: '1.75rem',
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      lineHeight: 1.4,
      marginBottom: theme.spacing(1),
    },
  },
  label: {
    fontSize: '1rem',
    lineHeight: 'normal',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      marginBottom: theme.spacing(0.5),
    },
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
    },
  },
}));

const CTABlock = ({ intro, employee, bookCta, confirmationText, form, typeHandle, ...props }) => {
  const classes = useStyles(),
    isSmall = useMediaQuery(theme => theme.breakpoints.down('sm')),
    [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <Box component={Container} fontFamily='fontFamily' {...props}>
      <Divider mb={props?.my} />

      <Grid container spacing={7} justify='space-between'>
        <Grid item xs={12} md={6}>
          <Box dangerouslySetInnerHTML={{ __html: intro }} />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <Box
            display='flex'
            flexDirection={isSmall ? 'row-reverse' : 'row'}
            justifyContent={isSmall ? 'space-between' : 'flex-start'}
            alignItems={isSmall ? 'flex-start' : 'center'}
          >
            <Picture
              width={{ xs: '96px', md: '218px' }}
              alt={employee[0].fullName}
              {...employee[0].photo}
            />
            <Box ml={{ xs: 0, md: 5 }} mr={{ xs: 4, md: 0 }}>
              <Box className={classes.jobTitle}>{employee[0].jobTitle}</Box>
              <Box className={classes.name}>{employee[0].fullName}</Box>
              <Box className={classes.label}>{employee[0].email}</Box>
              {employee[0].phoneNumber && (
                <Box className={classes.label}>{employee[0].phoneNumber}</Box>
              )}
              <Button
                variant='outlined'
                size='small'
                endIcon={<ChevronRightIcon />}
                title={`${bookCta} - ${employee[0].fullName}`}
                className={classes.button}
                onClick={handleOpenDialog}
              >
                {bookCta}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ContactFormDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullScreen={isSmall}
        employee={employee[0]}
        bookCta={bookCta}
        confirmationText={confirmationText}
        form={form}
      />
    </Box>
  );
};

export default CTABlock;

CTABlock.propTypes = {
  intro: PropTypes.string.isRequired,
  employee: PropTypes.array.isRequired,
  bookCta: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.number.isRequired,
    handle: PropTypes.string.isRequired,
  }).isRequired,
};
