import React from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContentSlider from '@c/ContentSlider';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    boxShadow: 'inset 1px 0 0 0 rgba(41, 52, 61, 0.12), inset 0 1px 0 0 rgba(41, 52, 61, 0.12)',
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      'grid-template-columns': 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      'grid-template-columns': 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      'grid-template-columns': 'repeat(4, 1fr)',
    },
  },
  gridItem: {
    boxShadow: 'inset 0 -1px 0 0 rgba(41, 52, 61, 0.12), inset -1px 0 0 0 rgba(41, 52, 61, 0.12)',
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(14)}px ${theme.spacing(7)}px`,
    },
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      width: '100%',
      '&::before': {
        content: '""',
        height: 0,
        'padding-bottom': '100%',
        'grid-row': '1 / 1',
        'grid-column': '1 / 1',
        display: 'inline-block',
        'vertical-align': 'top',
      },
      '&:first-child': {
        'grid-row': '1 / 1',
        'grid-column': '1 / 1',
      },
    },
  },
  gridContent: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'flex-direction': 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  slide: {
    padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
    boxShadow:
      'inset 1px 0 0 0 rgba(41, 52, 61, 0.12), inset 0 -1px 0 0 rgba(41, 52, 61, 0.12), inset -1px 0 0 0 rgba(41, 52, 61, 0.12), inset 0 1px 0 0 rgba(41, 52, 61, 0.12)',
  },
  textContainer: {
    '& > p': {
      margin: 0,
      padding: 0,
      color: theme.colors.dark,
      fontSize: '0.875rem',
      lineHeight: 1.71,
      textAlign: 'center',
      '& > a': {
        fontWeight: 500,
        color: theme.colors.dark,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.6875rem',
        lineHeight: 1.82,
      },
      [theme.breakpoints.up('sm')]: {
        width: '17.25rem',
      },
      [theme.breakpoints.up('md')]: {
        width: '15rem',
      },
      [theme.breakpoints.up('lg')]: {
        width: '16rem',
      },
    },
  },
}));

const ClientsCards = ({ intro, clients }) => {
  const classes = useStyles();

  return (
    <>
      {intro && (
        <Box
          className='space-p-l-6 space-p-r-6'
          pb={{ xs: 5, md: 12 }}
          dangerouslySetInnerHTML={{ __html: intro }}
        />
      )}

      <Hidden xsDown>
        <div className={classes.gridContainer}>
          {clients.map(client => (
            <div key={client.id} className={classes.gridItem}>
              <div className={classes.gridContent}>
                <Box mb={1}>
                  <Picture alt={client.logo[0]?.title} {...client.logo[0]} />
                </Box>
                <Box
                  className={classes.textContainer}
                  dangerouslySetInnerHTML={{ __html: client.text }}
                />
              </div>
            </div>
          ))}
        </div>
      </Hidden>

      <Hidden smUp>
        <Box px={{ xs: 1.5, md: 0 }}>
          <ContentSlider>
            {clients.map(client => (
              <div className={classes.slide} key={client.id}>
                <Box display='flex' alignItems='center' justifyContent='center' mb={1}>
                  <Picture alt={client.logo.title} {...client.logo[0]} />
                </Box>
                <Box
                  className={classes.textContainer}
                  dangerouslySetInnerHTML={{ __html: client.text }}
                />
              </div>
            ))}
          </ContentSlider>
        </Box>
      </Hidden>
    </>
  );
};

export default ClientsCards;

ClientsCards.propTypes = {
  clients: PropTypes.array.isRequired,
};

ClientsCards.defaultProps = {
  intro: null,
};
