import React from 'react';
import { useSelectedEntries } from '@hooks/cms';
import FeaturedContentGrid from '@c/FeaturedContentGrid';
import ClientsCards from '@c/ClientsCards';
import CareerCases from '@c/CareerCases';

const SelectedEntries = ({ entries, ...props }) => {
  const [selectedEntries, blogMainPageUrl, casesMainPageUrl] = useSelectedEntries(entries);

  switch (entries[0].sectionHandle) {
    case 'cases':
      return (
        <FeaturedContentGrid {...props} ctaUrl={`/${casesMainPageUrl}`} entries={selectedEntries} />
      );
    case 'blog':
      return (
        <FeaturedContentGrid
          {...props}
          ctaUrl={`/${blogMainPageUrl}`}
          smallGrid
          entries={selectedEntries}
        />
      );
    case 'clients':
      return <ClientsCards {...props} clients={selectedEntries} />;
    case 'careerListings':
      return <CareerCases {...props} entries={selectedEntries} />;
    default:
      return null;
  }
};

export default SelectedEntries;
