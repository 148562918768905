import styled, { css } from 'styled-components';

const mobile = css`
  height: auto;
`;

export default styled.div`
  padding-left: 0;
  padding-right: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
