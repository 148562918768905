import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Box, useTheme } from '@material-ui/core';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { usePageType } from '@context/PageTypeContext';
import VisibilitySensor from '@c/VisibilitySensor';
import Picture from '@c/Picture';
import ImageDescription from '@c/ImageDescription';
import Video from '@c/Video';
import ImageSlider from '@c/ImageSlider';
import Divider from '@c/Divider';
import InlineQuote from '@c/InlineQuote';
import SideBySide, { SideBySideContainer } from '@c/SideBySide';
import LatestEntries from '@c/LatestEntries';
import SelectedEntries from '@c/SelectedEntries';
import TeamCards from '@c/TeamCards';
import Quote from '@c/Quote';
import CTABlock from '@c/CTABlock';
import SellingPoints from '@c/SellingPoints';
import CTAButtonBlock from '@c/CTAButtonBlock';
import Cards from '@c/Cards';
import InfoText from '@c/InfoText';
import { getContentParams } from './ArticleContent.helpers';
import StyledArticleContent from './ArticleContent.styled';
import Links from '@c/Links';

const ArticleContent = ({ content, spacing, useOriginalImage, mt, mb, pt, pb, ...props }) => {
  const pageType = usePageType(),
    theme = useTheme(),
    [, setBackground] = useColorMode();

  const { spaces, gridContainerProps, gridItemProps, mediaItemProps } = useMemo(
    () => getContentParams(pageType),
    [pageType]
  );

  const childWrapSpacing = useCallback(
    index => {
      if (index === 0) {
        return {
          ...(mt ? { mt } : {}),
          ...(pt ? { pt } : {}),
        };
      }

      if (index === content.length - 1) {
        return {
          ...(mb ? { mb } : {}),
          ...(pb ? { pb } : {}),
        };
      }

      return {};
    },
    [content.length, mt, mb, pt, pb]
  );

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <StyledArticleContent pageType={pageType} {...props}>
      {content.map((block, index) => {
        switch (block.typeHandle) {
          case 'articleText':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box className='wysiwyg' {...spaces} {...childWrapSpacing(index)}>
                  <Container>
                    <Box {...spacing}>
                      <Grid container {...gridContainerProps}>
                        <Grid
                          item
                          {...gridItemProps}
                          dangerouslySetInnerHTML={{ __html: block.textarea }}
                        />
                      </Grid>
                    </Box>
                  </Container>
                </Box>
              </VisibilitySensor>
            );
          case 'articleImage':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...spaces} {...childWrapSpacing(index)}>
                  {block.fullWidth ? (
                    <Box {...spacing}>
                      <Picture alt={block.altText} width='100%' {...block.image[0]} />
                      {block.imageDescription && (
                        <ImageDescription
                          dangerouslySetInnerHTML={{ __html: block.imageDescription }}
                        />
                      )}
                    </Box>
                  ) : (
                    <Container>
                      <Box {...spacing}>
                        <Grid container {...gridContainerProps}>
                          <Grid item {...mediaItemProps}>
                            <Picture
                              alt={block.altText}
                              {...block.image[0]}
                              useOriginalImage={useOriginalImage}
                              maxHeight='100vh'
                              marginLeft='auto'
                              marginRight='auto'
                            />
                            {block.imageDescription && (
                              <ImageDescription
                                dangerouslySetInnerHTML={{ __html: block.imageDescription }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                  )}
                </Box>
              </VisibilitySensor>
            );
          case 'articleVideo':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...spaces} {...childWrapSpacing(index)}>
                  <Container>
                    <Grid container {...gridContainerProps}>
                      <Grid item {...mediaItemProps}>
                        <Box {...spacing}>
                          <Video videoId={block.videoId} videoHost={block.videoHost} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </VisibilitySensor>
            );
          case 'imageGallery':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...spaces} {...childWrapSpacing(index)}>
                  <ImageSlider
                    gridContainerProps={gridContainerProps}
                    spacing={spacing}
                    {...block}
                  />
                </Box>
              </VisibilitySensor>
            );
          case 'separator':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box my={{ xs: 0, md: `${block.margin - 96}px` }} {...childWrapSpacing(index)}>
                  <Container>
                    <Grid container {...gridContainerProps}>
                      <Grid item {...gridItemProps}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </VisibilitySensor>
            );
          case 'inlineQuote':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box className='wysiwyg' {...spaces} {...childWrapSpacing(index)}>
                  <Container>
                    <Box {...spacing}>
                      <Grid container {...gridContainerProps}>
                        <Grid item {...gridItemProps}>
                          <InlineQuote text={block.text} source={block.source} />
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </Box>
              </VisibilitySensor>
            );
          case 'sideBySide':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...childWrapSpacing(index)}>
                  <SideBySideContainer className='wysiwyg' {...spaces}>
                    <Container>
                      <SideBySide text={block.text} image={block.image} />
                    </Container>
                  </SideBySideContainer>
                </Box>
              </VisibilitySensor>
            );
          case 'selectedEntries':
            return (
              !!block.selectedEntries.length && (
                <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                  <Box {...childWrapSpacing(index)}>
                    <Container>
                      <Box my={{ xs: 19.5, md: 32 }}>
                        <SelectedEntries
                          intro={block.intro}
                          entries={block.selectedEntries}
                          readMoreCta={block.readMoreCta}
                        />
                      </Box>
                    </Container>
                  </Box>
                </VisibilitySensor>
              )
            );
          case 'latestEntries':
            return (
              !!block.latestEntries.length && (
                <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                  <Box {...childWrapSpacing(index)}>
                    <Container>
                      <Box my={{ xs: 19.5, md: 32 }}>
                        <LatestEntries
                          limit={block.limit}
                          sectionId={block.latestEntries}
                          intro={block.intro}
                          readMoreCta={block.readMoreCta}
                        />
                      </Box>
                    </Container>
                  </Box>
                </VisibilitySensor>
              )
            );
          case 'employees':
            return (
              !!block.employees.length && (
                <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                  <Box {...childWrapSpacing(index)}>
                    <Container>
                      <Box {...spaces}>
                        <TeamCards
                          title={block.blockTitle}
                          employees={block.employees}
                          gridContainerProps={gridContainerProps}
                          gridItemProps={gridItemProps}
                        />
                      </Box>
                    </Container>
                  </Box>
                </VisibilitySensor>
              )
            );
          case 'quote':
            return (
              <Quote
                className='wysiwyg'
                {...block}
                id={block.id}
                key={block.id}
                gridContainerProps={gridContainerProps}
                gridItemProps={gridItemProps}
              />
            );
          case 'cta':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <CTABlock
                  my={{ xs: 7, md: 19.5 }}
                  mb={{ md: 12 }}
                  {...block}
                  {...childWrapSpacing(index)}
                />
              </VisibilitySensor>
            );
          case 'sellingPoint':
            if (content[index - 1]?.typeHandle === 'sellingPoint') {
              return null;
            }

            // collect all the next selling points
            let sellingPoints = [block];
            for (let i = index + 1; i < content.length; i += 1) {
              if (content[i]?.typeHandle === 'sellingPoint') {
                sellingPoints = [...sellingPoints, content[i]];
              } else {
                break;
              }
            }

            return (
              <VisibilitySensor
                onVisible={handleOnVisible}
                id={sellingPoints[0]?.id}
                key={sellingPoints[0]?.id}
              >
                <Box {...childWrapSpacing(index)}>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} md={11}>
                        <Box
                          pl={{ xs: 0, md: 8 }}
                          className={gridItemProps?.className || ''}
                          {...spaces}
                        >
                          <SellingPoints data={sellingPoints} articleContent />
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </VisibilitySensor>
            );
          case 'ctaButton':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...childWrapSpacing(index)}>
                  <Container>
                    <Grid container {...gridContainerProps}>
                      <Grid item {...gridItemProps}>
                        <CTAButtonBlock my={{ xs: 7, md: 19.5 }} mb={{ md: 12 }} {...block} />
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </VisibilitySensor>
            );
          case 'cards':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...childWrapSpacing(index)}>
                  <Cards {...block} />
                </Box>
              </VisibilitySensor>
            );
          case 'infoText':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...childWrapSpacing(index)}>
                  <InfoText {...block} />
                </Box>
              </VisibilitySensor>
            );
          case 'links':
            return (
              <VisibilitySensor onVisible={handleOnVisible} id={block.id} key={block.id}>
                <Box {...childWrapSpacing(index)}>
                  <Links {...block} />
                </Box>
              </VisibilitySensor>
            );
          default:
            return null;
        }
      })}
    </StyledArticleContent>
  );
};

export default ArticleContent;

ArticleContent.propTypes = {
  content: PropTypes.array.isRequired,
  spacing: PropTypes.object,
  useOriginalImage: PropTypes.bool,
  mt: PropTypes.any,
  mb: PropTypes.any,
  pt: PropTypes.any,
  pb: PropTypes.any,
};

ArticleContent.defaultProps = {
  spacing: {},
  useOriginalImage: false,
  mt: undefined,
  mb: undefined,
  pt: undefined,
  pb: undefined,
};
