import styled, { css } from 'styled-components';

const mobile = css`
  && p {
    font-size: 1.0625rem;
    line-height: 1.65;
  }
`;

export default styled.div`
  && p {
    margin: 0;
    font-family: schoolbook, serif;
    font-style: italic;
    font-size: 1.5rem;
    line-height: 1.67;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
