import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => ({
  gridContainer: ({ articleContent }) => ({
    ...(articleContent
      ? {
          [theme.breakpoints.up('md')]: {
            width: `calc(100% + ${theme.spacing(19.5)}px)`,
            margin: `-${theme.spacing(9.75)}px`,
          },
        }
      : {}),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  }),
  gridItem: ({ articleContent }) => ({
    ...(articleContent
      ? {
          [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(9.75)}px !important`,
          },
        }
      : {}),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  }),
}));

const SellingPoints = ({ data, articleContent = false }) => {
  const classes = useStyles({ articleContent }),
    isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={isSmall ? 5 : 8} className={classes.gridContainer}>
      {data.map(sellingPoint => (
        <Grid item xs={12} md={6} className={classes.gridItem} key={sellingPoint.id}>
          {sellingPoint.icon[0] && (
            <Picture width='auto' alt={sellingPoint.icon[0].title} {...sellingPoint.icon[0]} />
          )}
          <div dangerouslySetInnerHTML={{ __html: sellingPoint.text }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SellingPoints;

SellingPoints.propTypes = {
  data: PropTypes.array.isRequired,
  articleContent: PropTypes.bool,
};
