import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Picture from '@c/Picture';
import StyledIcon from './Icon.styled';

const StyledIcons = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 2.5rem;
    }
    ${theme.breakpoints.down('md')} {
      margin-bottom: 3rem;
    }
  `}
`;

const Icons = ({ icons }) => {
  return (
    <StyledIcons className='space-p-r-6 space-p-l-6'>
      <Grid container>
        {icons.map((icon, index) => (
          <Grid item xs={4} key={index}>
            <StyledIcon className={index > 2 && 'space-m-t-16'}>
              <Picture url={icon.url} />
            </StyledIcon>
          </Grid>
        ))}
      </Grid>
    </StyledIcons>
  );
};

export default Icons;
