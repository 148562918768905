import styled, { css } from 'styled-components';
import { typography } from '@material-ui/system';
import { PAGE_TYPE_LAYOUT, PAGE_TYPE_CASE, PAGE_TYPE_ARTICLE } from '@context/PageTypeContext';

const layoutStyles = css``;
const caseStyles = css`
  font-family: schoolbook, serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: EuclidCircular, serif;
  }

  p {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
    line-height: ${({ theme }) => theme.typography.body1.lineHeight};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: ${({ theme }) => theme.typography.body1[theme.breakpoints.down('sm')].fontSize};
      line-height: ${({ theme }) =>
        theme.typography.body1[theme.breakpoints.down('sm')].lineHeight};
    }
  }
`;
const articleStyles = css`
  font-family: schoolbook, serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: EuclidCircular, serif;
  }

  & > div:first-child.wysiwyg {
    margin-top: ${({ theme }) => theme.spacing(4)}px!important;

    p:first-child,
    h2:first-child + p {
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
      font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
      line-height: ${({ theme }) => theme.typography.body1.lineHeight};

      ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: ${({ theme }) => theme.typography.body1[theme.breakpoints.down('sm')].fontSize};
        line-height: ${({ theme }) =>
          theme.typography.body1[theme.breakpoints.down('sm')].lineHeight};
      }
    }
  }

  p {
    ${({ theme }) => theme.breakpoints.up('xl')} {
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
      font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
      line-height: ${({ theme }) => theme.typography.body1.lineHeight};
    }
  }
`;

export default styled.div`
  ${props => props.pageType === PAGE_TYPE_LAYOUT && layoutStyles}
  ${props => props.pageType === PAGE_TYPE_CASE && caseStyles}
  ${props => props.pageType === PAGE_TYPE_ARTICLE && articleStyles}

  .wysiwyg {
    *:not(:empty) {
      & + h1,
      & + h2,
      & + h3,
      & + h4 {
        margin-top: ${({ theme }) => theme.spacing(12)}px;

        ${({ theme }) => theme.breakpoints.down('sm')} {
          margin-top: ${({ theme }) => theme.spacing(7)}px;
        }
      }
    }
  }

  p > a {
    font-weight: 500;
  }

  ${typography}
`;
