import styled, { css } from 'styled-components';

const mobile = css`
  position: static;
  background-color: transparent;
`;

export default styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
