import { Container, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card.js';
import CTAButton from '@c/CTAButton';
import { useColorByBackground } from '../../hooks/helpers';

const useStyles = makeStyles(theme => {
  return {
    cards: {
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    title: {
      lineHeight: '1.22',
      fontSize: '1.625rem',

      [theme.breakpoints.up('sm')]: {
        fontSize: '4.5vw',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '3.56rem',
      },
    },
    subtitle: {
      fontSize: '1.25rem',
      fontWeight: '500',
      [theme.breakpoints.up('sm')]: {
        fontSize: '3.26vw',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '3vw',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.625rem',
      },
    },
    button: {
      padding: `${theme.spacing(3)}px ${theme.spacing(10)}px`,
      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(3)}px ${theme.spacing(15)}px`,
      },
    },
    cardWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

const Cards = ({ blockTitle, subtitle, color, cards, ctaButton }) => {
  const classes = useStyles();
  const button = ctaButton[0];
  const textColor = useColorByBackground(color);

  return (
    <div className={classes.cards}>
      <Container>
        <Grid container direction='row' justify='center'>
          <Grid item xs={12} sm={10} lg={8}>
            <div>
              {blockTitle && <h2 className={`${classes.title} space-m-b-7`}>{blockTitle}</h2>}

              {subtitle && <h3 className={`${classes.subtitle} space-m-b-12`}>{subtitle}</h3>}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {cards.map(card => {
            return (
              <Grid item xs={12} md={4} key={card.id} className={classes.cardWrapper}>
                <Card
                  background={color}
                  color={textColor}
                  text={card.text}
                  title={card.cardTitle}
                  image={card.image[0]}
                />
              </Grid>
            );
          })}
        </Grid>

        {button && (
          <Grid container justify='center' className='space-m-t-10'>
            <Grid item>
              <CTAButton
                entry={button?.entry[0]}
                href={button.externalUrl}
                className={classes.button}
              >
                {button.text}
              </CTAButton>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

Cards.propTypes = {
  blockTitle: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  ctaButton: PropTypes.array,
  cards: PropTypes.array,
};

export default Cards;
