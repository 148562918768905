import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.5px;
`;
