import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => {
  return {
    card: ({ background, color }) => ({
      padding: '2.625rem',
      maxWidth: '31.25rem',
      backgroundColor: background || theme.colors.orange,
      color: color || theme.colors.dark,
      [theme.breakpoints.up('sm')]: {
        height: '36.3125rem',
      },
    }),
    image: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(5),
      minHeight: '12.5rem',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '2.625rem',
      },
    },
    picture: {
      maxWidth: '12rem',
      maxHeight: '12rem',
    },
    title: {
      ...theme.typography.h3,
      marginBottom: theme.spacing(3),
    },
    text: {
      ...theme.typography.body2,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 4,
      whiteSpace: 'pre-wrap',
    },
  };
});

const Card = ({ background, color, text, title, image }) => {
  const classes = useStyles({ background, color });

  return (
    <div className={classes.card}>
      <div className={classes.image}>
        <div className={classes.picture}>
          <Picture url={image.url} />
        </div>
      </div>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.text}>{text}</div>
    </div>
  );
};

Card.propTypes = {
  bg: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default Card;
