import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, useTheme } from '@material-ui/core';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { useColorByBackground, useHeaderModeByBackground } from '@hooks/helpers';
import VisibilitySensor from '@c/VisibilitySensor';
import StyledQuote from './Quote.styled';
import Icons from './Icons/Icons';

const Quote = ({
  id,
  text,
  fullScreen,
  backgroundColor,
  overlayOpacity,
  icons,
  image,
  gridContainerProps,
  gridItemProps,
  ...props
}) => {
  const [, setBackground] = useColorMode(),
    theme = useTheme(),
    overlayColor = backgroundColor || theme.colors.black,
    overlay = !!overlayOpacity && { color: backgroundColor, opacity: overlayOpacity },
    headerMode = useHeaderModeByBackground(overlayColor),
    color = useColorByBackground(overlayColor);

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: {
          color: backgroundColor,
          overlay,
          image: image && image[0] ? image[0] : null,
          altText: image && image[0] ? image[0].title : null,
        },
        header: {
          mode: headerMode,
        },
        visibility: {
          hiddenContentVisible: false,
          visibleContentId: id,
        },
      });
    },
    [setBackground, backgroundColor, image, headerMode, overlay]
  );

  return (
    <VisibilitySensor
      id='quote'
      offsetTop
      onVisible={handleOnVisible}
      forceHiddenInactiveState
      disabled={!fullScreen}
    >
      <StyledQuote
        fullScreen={fullScreen}
        bgColor={backgroundColor}
        textColor={color}
        overlay={overlay}
        bgImage={image && image[0]}
        {...props}
      >
        <Container>
          <Grid container {...gridContainerProps} alignItems='center'>
            {icons && icons.length > 0 && (
              <Grid item xs={12} lg={4}>
                <Icons icons={icons} />
              </Grid>
            )}
            <Grid item {...gridItemProps} md={12} dangerouslySetInnerHTML={{ __html: text }} />
          </Grid>
        </Container>
      </StyledQuote>
    </VisibilitySensor>
  );
};

export default Quote;

Quote.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  overlayOpacity: PropTypes.string,
  image: PropTypes.array,
  gridContainerProps: PropTypes.object,
  gridItemProps: PropTypes.object,
};

Quote.defaultProps = {
  overlayOpacity: null,
  image: null,
};
